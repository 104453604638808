/* ClientWeekViewMobile.css */

.cwvm-container {
    font-family: 'Roboto', sans-serif;
    background-color: #f8fafc;
    color: #334155;
    padding: 1rem;
  }
  
  .cwvm-week-view {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .cwvm-day-column {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .cwvm-day-header {
    background-color: #e2e8f0;
    padding: 0.75rem;
    text-align: center;
    border-bottom: 1px solid #cbd5e0;
  }

  .cwvm-day-name {
    font-weight: 600;
    color: #4a5568;
    font-size: 0.9rem;
  }
  
  .cwvm-day-number {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2d3748;
    margin-top: 0.25rem;
  }
  
  .cwvm-appointments-list {
    padding: 0.5rem;
  }
  
  .cwvm-appointment {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-left: 4px solid;
    transition: all 0.2s ease;
  }
  
  .cwvm-appointment:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  
  .cwvm-appointment-time {
    font-weight: 600;
    color: #2b6cb0;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }
  
  .cwvm-appointment-employee {
    color: #4a5568;
    display: flex;
    align-items: center;
  }

  .cwvm-appointment-details {
    font-size: 0.875rem;
    color: #718096;
    margin-top: 0.25rem;
  }
  
  .cwvm-appointment-service {
    font-size: 0.875rem;
    color: #718096;
  }
  
  .cwvm-appointment-status {
    font-size: 0.75rem;
    color: #ed8936;
    margin-top: 0.25rem;
    font-style: italic;
  }
  
  .cwvm-icon {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }


  /* Styles pour les différents états des rendez-vous */
  .cwvm-appointment-libre {
    background-color: #fee2e2;
    color: #991b1b;
  }
  
  .cwvm-appointment-reserve {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .cwvm-appointment.non-assigned {
    border-left-color: #e53e3e; /* Rouge pour non assigné */
  }
  
  .cwvm-appointment.assigned {
    /* La couleur de la bordure est définie inline dans le style */
  }

  .cwvm-appointment.pending {
    border-left-color: #ed8936; /* Orange pour en attente de validation */
  }

  .cwvm-appointment-assigne {
    /* La couleur de fond sera définie dynamiquement */
  }
  
  /* Animation pour les rendez-vous libres */
  @keyframes pulse-red {
    0% {
      box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(239, 68, 68, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
    }
  }
  
  .cwvm-appointment-libre {
    animation: pulse-red 2s infinite;
  }
  
  .cwvm-appointment-libre:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
  
  /* Style pour les rendez-vous en cours */
  .cwvm-appointment-in-progress {
    background-color: #ffedd5;
    color: #9a3412;
    border-left: 4px solid #fb923c;
  }
  
  /* Responsive design */
  @media (min-width: 640px) {
    .cwvm-week-view {
      flex-direction: row;
      overflow-x: auto;
    }
  
    .cwvm-day-column {
        background-color: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;
      }
  }