.auth-container {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f3f4f6;
  padding: 1rem;
}

.auth-box {
  width: 100%;
  max-width: 400px;
  background: white;
  border-radius: 0;
  box-shadow: none;
  padding: 1rem;
  min-height: 100vh;
}

.auth-header {
  text-align: center;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
}

.auth-logo {
  width: 100%;
  max-width: 125px;
  height: auto;
  margin: 0 auto 1rem;
  display: block;
}

.auth-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #4b5563;
}

.input-icon {
  width: 1rem;
  height: 1rem;
  color: #6b7280;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: border-color 0.2s;
}

.input-field:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.error-message {
  color: #dc2626;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.75rem;
  background-color: #fee2e2;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.success-message {
  color: #16a34a;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.75rem;
  background-color: #dcfce7;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.auth-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3b82f6;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.auth-button:hover {
  background-color: #2563eb;
}

.auth-button:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

.remember-me {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.remember-me-checkbox {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.remember-me-label {
  font-size: 0.875rem;
  color: #4b5563;
}

.auth-footer {
  text-align: center;
  margin-top: 1rem;
}

.footer-link {
  color: #3b82f6;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s;
  padding: 0.5rem;
  display: inline-block;
  border-radius: 0.25rem;
}

.footer-link:hover {
  color: white;
  background-color: #3b82f6;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

@media (min-width: 640px) {
  .auth-box {
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    min-height: auto;
    margin: 2rem 0;
  }
  
  .auth-logo {
    max-width: 150px;
  }
  
  .auth-header {
    padding-top: 0;
  }
}
