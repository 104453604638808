.cdv-container {
    padding: 1rem;
    background-color: #f8fafc;
  }
  
  .cdv-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #2d3748;
  }
  
  .cdv-employee-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .cdv-employee-row {
    background-color: #ffffff;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .cdv-employee-name {
    padding: 0.75rem;
    font-weight: bold;
    background-color: #edf2f7;
    border-left: 4px solid;  /* La couleur sera définie en inline */
  }
  
  .cdv-appointments {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  
  .cdv-appointment {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cdv-appointment:hover {
    background-color: #f7fafc;
  }
  
  .cdv-appointment-time {
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #4a5568;
  }
  
  .cdv-appointment-details {
    font-size: 0.875rem;
    color: #718096;
    margin-top: 0.25rem;
  }
  
  .cdv-icon {
    margin-right: 0.5rem;
  }
  
  .cdv-add-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #4299e1;
    color: white;
    border-radius: 9999px;
    padding: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;
  }
  
  .cdv-add-button:hover {
    background-color: #3182ce;
  }

  .cdv-appointment-status {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    margin-top: 0.25rem;
  }
  
  .cdv-icon {
    margin-right: 0.25rem;
  }
  
  .cdv-in-progress {
    background-color: #FEF3C7;
    border-left: 3px solid #F59E0B;
  }
  
  .cdv-completed {
    background-color: #D1FAE5;
    border-left: 3px solid #10B981;
  }
  
  .cdv-assigned {
    background-color: #E0F2FE;
    border-left: 3px solid #3B82F6;
  }
  
  .cdv-unassigned {
    background-color: #FEE2E2;
    border-left: 3px solid #EF4444;
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }