/* Planning.css */

.pv-container {
    font-family: 'Roboto', sans-serif;
    background-color: #f8fafc;
    color: #334155;

    width: 100%;
    max-width: 1980px;
    margin: 0 auto;
    padding: 2px;
  }
  .pv-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1e293b;
  }
  .pv-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .pv-view-buttons {
    display: flex;
    gap: 8px;
  }
  
  .pv-date-navigation {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .pv-date-range {
    font-weight: 500;
    color: #1e293b;
  }

 /* Styles pour les boutons d'action dans les modals */

/* Style de base commun à tous les boutons */
.pv-button-action {
  padding: 0.25rem 0.75rem; /* px-3 py-1 */
  font-size: 0.875rem; /* text-sm */
  border-radius: 0.25rem; /* rounded */
  transition: background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

.pv-button-action:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 0 4px currentColor;
}

/* Style pour le bouton Approuver */
.pv-button-approve {
  background-color: #22c55e; /* bg-green-500 */
  color: white;
}

.pv-button-approve:hover {
  background-color: #16a34a; /* hover:bg-green-600 */
}

/* Style pour le bouton Refuser */
.pv-button-reject {
  background-color: #ef4444; /* bg-red-500 */
  color: white;
}

.pv-button-reject:hover {
  background-color: #dc2626; /* hover:bg-red-600 */
}

/* Style pour le bouton Annuler */
.pv-button-cancel {
  background-color: #eab308; /* bg-yellow-500 */
  color: white;
}

.pv-button-cancel:hover {
  background-color: #ca8a04; /* hover:bg-yellow-600 */
}

/* Style pour les icônes à l'intérieur des boutons */
.pv-button-action svg {
  margin-right: 0.25rem; /* mr-1 */
  width: 12px;
  height: 12px;
}
/* Style pour le bouton Annuler (gris) */
.pv-button-cancel-gray {
  background-color: #e5e7eb; /* bg-gray-200 */
  color: #1f2937; /* text-gray-800 */
}

.pv-button-cancel-gray:hover {
  background-color: #d1d5db; /* hover:bg-gray-300 */
}

/* Style pour le bouton Réserver */
.pv-button-book {
  background-color: #3b82f6; /* bg-blue-500 */
  color: white;
}

.pv-button-book:hover {
  background-color: #2563eb; /* hover:bg-blue-600 */
}

/* Style pour les boutons gris (Annuler, Fermer, etc.) */
.pv-button-gray {
  background-color: #e5e7eb; /* bg-gray-200 */
  color: #1f2937; /* text-gray-800 */
}

.pv-button-gray:hover {
  background-color: #d1d5db; /* hover:bg-gray-300 */
}

/* Vous pouvez garder la classe pv-button-cancel-gray comme un alias si vous le souhaitez */
.pv-button-cancel-gray {
  background-color: #e5e7eb; /* bg-gray-200 */
  color: #1f2937; /* text-gray-800 */
}

.pv-button-cancel-gray:hover {
  background-color: #d1d5db; /* hover:bg-gray-300 */
}
  
  /* Style pour les boutons de navigation (précédent/suivant) */
.pv-button-icon {
    padding: 6px;
    background-color: transparent;
    color: #64748b;
    border: none;
  }

  .pv-button-icon:hover {
  background-color: #f1f5f9;
  color: #1e293b;
}
  /* Style pour le bouton "Aujourd'hui" */
  .pv-today-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #f8fafc;
    color: #3b82f6;
    border-color: #3b82f6;
  }

  .pv-today-button:hover {
    background-color: #e0f2fe;
  }
  
  .pv-view-container {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
  }

  .pv-week-grid {
    display: grid;
    grid-template-columns: auto repeat(7, 1fr);
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .pv-header {
    font-weight: 600;
    text-align: center;
    padding: 12px;
    background-color: #e2e8f0;
    border-bottom: 2px solid #cbd5e0;
    color: #1e293b;
  }
  
  .pv-employee-name {
    font-weight: 600;
    padding: 12px;
    background-color: #f1f5f9;
    border-right: 2px solid #cbd5e0;
    color: #475569;
  }
  
  .pv-day-cell {
    border: 1px solid #e2e8f0;
    padding: 8px;
    min-height: 120px;
    transition: background-color 0.3s ease;
  }
  
  .pv-day-cell:hover {
    background-color: #f1f5f9;
  }
  
  .pv-appointment {
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 6px;
    font-size: 12px;
    cursor: move;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pv-appointment:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .pv-appointment-title {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .pv-appointment-time {
    display: flex;
    align-items: center;
    color: #64748b;
  }
  
  .pv-modal {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .pv-modal-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #1e293b;
    border-bottom: 2px solid #e2e8f0;
    padding-bottom: 12px;
  }
  
  .pv-form-group {
    margin-bottom: 16px;
  }
  
  .pv-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #475569;
  }
  
  .pv-input, .pv-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .pv-input:focus, .pv-select:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
  
  /* Styles de base pour tous les boutons */
  .pv-button {
    padding: 6px 12px;
    font-size: 13px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: #f8fafc;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
    }
  
  /* Style pour le bouton actif */
  .pv-button-primary {
    background-color: #e2e8f0;
    color: #1e293b;
    border-color: #cbd5e1;
  }
  
  .pv-button-primary:hover {
    background-color: #cbd5e1;
  }
  
  .pv-button-secondary {
    background-color: #e2e8f0;
    color: #475569;
  }
  
  .pv-button-secondary:hover {
    background-color: #cbd5e0;
  }
  
  .pv-button-danger {
    background-color: #ef4444;
    color: #ffffff;
  }
  
  .pv-button:hover {
    background-color: #f1f5f9;
  }
  
  .pv-unassigned {
    background-color: #fee2e2;
  }
  
  .pv-today {
    background-color: #e0f2fe;
  }
  
  @media (max-width: 768px) {
    .pv-week-grid {
      grid-template-columns: auto;
    }
    
    .pv-header {
      position: sticky;
      top: 0;
      z-index: 10;
    }
    
    .pv-employee-name {
      position: sticky;
      left: 0;
      z-index: 5;
    }
  /* Conteneur des boutons pour assurer un espacement uniforme */
.pv-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .pv-view-buttons {
    display: flex;
    gap: 4px;
  }
  
  .pv-date-navigation {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

  .pv-appointment {
    position: relative;
    /* ... autres styles existants ... */
  }
  
  .pv-copy-icon-wrapper {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .pv-copy-icon {
    color: #4a5568;
  }
  
  .pv-appointment:hover .pv-copy-icon-wrapper {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .pv-appointment:hover .pv-copy-icon {
    color: #2d3748;
  }

  .pv-appointment {
    margin-bottom: 4px;
    border-radius: 3px;
    font-size: 0.75rem;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .pv-appointment:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  
  .pv-appointment-title {
    font-weight: 600;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
  }
  
  .pv-appointment-time {
    font-size: 0.7rem;
    opacity: 0.75;
    display: flex;
    align-items: center;
  }
  
  .pv-appointment svg {
    flex-shrink: 0;
  }
  
  .pv-day-cell {
    border: 1px solid #e2e8f0;
    padding: 4px;
    min-height: 100px;
    transition: background-color 0.2s ease;
  }
  
  .pv-day-cell:hover {
    background-color: #f8fafc;
  }