@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import url('ClientPlanning.css');*/
@import url('Planning.css');
@import url('PlanningStyles.css');
@import url('ClientManagement.css');
@import url('ClientWeekViewMobile.css');  
@import url('ClientDayView.css');
@import url('pv.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate-fade-in-down {
  animation: fadeInDown 0.3s ease-out;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.client-management-page {
  position: relative;
}

.toggle-header-button {
  position: absolute;
  top: 0;
  right: 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 0 0 5px 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-header-button:hover {
  background-color: #e0e0e0;
}

.client-management-header {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 200px; /* Ajustez cette valeur selon la hauteur réelle de votre en-tête */
  opacity: 1;
  overflow: hidden;
}

.client-management-header.hidden {
  max-height: 0;
  opacity: 0;
}
.week-grid {
  display: grid;
  grid-template-columns: 150px repeat(7, 1fr);
  gap: 1px;
  background-color: #e5e7eb;
}

.employee-header, .day-header, .employee-name, .day-cell {
  background-color: white;
  padding: 8px;
}

.employee-header, .employee-name {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: #f3f4f6;
}

.day-header {
  text-align: center;
  font-weight: bold;
}

.day-cell {
  min-height: 100px;
}

.appointment {
  margin-bottom: 4px;
  padding: 4px;
  border-radius: 4px;
  font-size: 0.8rem;
}
.App-link {
  color: #61dafb;
}
.dragging {
  opacity: 0.5;
}

.drag-over {
  background-color: rgba(0, 0, 0, 0.1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.client-week-view {
  height: 100vh; /* ou une hauteur spécifique */
}

.week-grid {
  min-width: max-content; /* Pour s'assurer que la grille ne se rétrécit pas trop */
}

.day-cell {
  min-width: 150px; /* Ajustez selon vos besoins */
}






@media (max-width: 768px) {
  .client-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .client-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }

  .color-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  .client-details {
    flex-grow: 1;
    min-width: 0;
  }

  .client-details h3 {
    font-size: 1rem;
    margin: 0;
  }

  .client-details p {
    font-size: 0.875rem;
    margin: 0;
  }

  .week-grid {
    grid-template-columns: 100px repeat(7, minmax(100px, 1fr));
  }

  .day-cell {
    min-width: 100px;
  }
}
html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

* {
  box-sizing: border-box;
}

.client-management-page {
  max-width: 100%;
  overflow-x: hidden;
}




/* Nouvelles règles pour mettre en évidence le jour actuel */
.day-header.today {
  background-color: #b3d9ff; /* Bleu moins pâle pour l'en-tête */
  font-weight: bold;
}

.day-cell.today {
  background-color: #cce6ff; /* Bleu moins pâle pour les cellules */
}

.day-cell.today:hover {
  background-color: #99ccff; /* Un peu plus foncé au survol */
}

/* Style spécifique pour la ligne "NON ASSIGNE" */
.employee-name.non-assigne {
  background-color: #f0f0f0; /* Gris clair pour différencier */
  font-weight: bold;
}

/* Assurez-vous que la cellule "NON ASSIGNE" du jour actuel est également mise en évidence */
.day-cell.non-assigne.today {
  background-color: #cce6ff; /* Même couleur que les autres cellules du jour actuel */
}

.leave-indicator {
  position: relative;
  height: 100%;
  min-height: 80px;
  background: repeating-linear-gradient(
    -45deg,
    #f8fafc,
    #f8fafc 8px,
    #f1f5f9 8px,
    #f1f5f9 16px
  );
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.leave-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.leave-content .leave-icon {
  color: #6366f1;
  margin-right: 8px;
}

.leave-content span {
  color: #4b5563;
  font-size: 0.875rem;
  font-weight: 500;
}

.leave-icon {
  color: #6b7280;
}

/* Ajout d'un effet hover subtil */
.leave-indicator:hover .leave-content {
  background-color: rgba(255, 255, 255, 0.95);
}


.employee-offday-indicator {
  background-color: #F3F4F6;
  border-radius: 0.25rem;
  margin: 0.125rem 0;
  font-size: 0.75rem;
  padding: 0.25rem;
  border-left: 2px solid #E5E7EB;
}

.employee-offday-content {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.employee-offday-icon {
  color: #6B7280;
}

.employee-offday-text {
  color: #4B5563;
  font-weight: 500;
}

.total-hours {
  text-align: left;
  padding: 0px;
  margin-top: 0px;
  font-size: 0.755rem;
}



.appointment {
  min-height: 40px; /* ou une valeur appropriée */
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.shadow-green-glow {
  box-shadow: 0 0 0 2px #10B981, 0 0 0 4px rgba(16, 185, 129, 0.3), 0 0 10px rgba(16, 185, 129, 0.5);
}

.shadow-red-glow {
  box-shadow: 0 0 0 2px #EF4444, 0 0 0 4px rgba(239, 68, 68, 0.3), 0 0 10px rgba(239, 68, 68, 0.5);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}
