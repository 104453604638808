/* PlanningStyles.css */

.pv-appointment {
  width: 100%;
  margin-bottom: 4px;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.2s ease;
}
  
  .pv-appointment:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .pv-appointment-title {
    font-weight: 600;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  
  .pv-appointment-title svg {
    margin-right: 6px;
    flex-shrink: 0;
  }
  
  .pv-appointment-time {
    font-size: 0.75rem;
    background-color: white;
    color: #333;
    border-radius: 4px;
    padding: 2px 6px;
    display: inline-flex;
    align-items: center;
    margin-top: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .pv-appointment-time svg {
    margin-right: 4px;
  }
  
  .pv-appointment-employee {
    font-size: 0.75rem;
    margin-top: 4px;
    display: flex;
    align-items: center;
  }
  
  .pv-appointment-employee svg {
    margin-right: 4px;
  }
  
    
  /* Styles pour les différents états des rendez-vous */
  .pv-appointment-libre {
    background-color: #f3451080; /* Bleu clair */
    color: #962f1d; /* Bleu foncé pour le texte */
  }
  
  .pv-appointment-reserve {
    background-color: #FDE68A; /* Jaune doux */
    color: #92400E; /* Marron pour le texte */
  }
  
  .pv-appointment-assigne {
    /* La couleur de fond sera définie dynamiquement en fonction de la couleur du client */
    /* Le texte sera ajusté en fonction de la luminosité de la couleur de fond */
  }
  
  /* Styles pour l'affichage du jour */
  .pv-day-view .pv-appointment {
    margin-bottom: 8px;
  }
  
  /* Styles pour l'affichage de la semaine */
  .pv-week-view .pv-appointment {
    margin-right: 6px;
  }
  
  /* Styles pour l'affichage mobile de la semaine */
  .pv-week-view-mobile .pv-appointment {
    margin-bottom: 6px;
  }
  /* Styles spécifiques pour la vue semaine */
.pv-week-appointment {
  height: 100%;
  min-height: 24px;
  font-size: 0.75rem;
  padding: 2px 4px;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
}

.pv-week-appointment .pv-appointment-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.pv-week-appointment .pv-appointment-title,
.pv-week-appointment .pv-appointment-time {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pv-week-appointment .pv-appointment-title svg,
.pv-week-appointment .pv-appointment-time svg {
  flex-shrink: 0;
  margin-right: 2px;
}

.pv-week-appointment .pv-appointment-client-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pv-week-appointment .pv-appointment-time {
  font-size: 0.7rem;
  opacity: 0.8;
}

/* Ajustements pour les écrans plus petits */


.pv-appointment-status {
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 10px;
}

.pv-appointment-libre .pv-appointment-status {
  color: #1E3A8A; /* Bleu foncé pour le contraste sur fond bleu clair */
}

.pv-appointment-reserve .pv-appointment-status {
  color: #92400E; /* Marron pour le contraste sur fond jaune */
}

.pv-appointment-assigne .pv-appointment-status {
  color: inherit; /* Utilise la couleur du texte définie pour l'appointment */
}

@media (max-width: 768px) {
  .pv-week-appointment {
    font-size: 0.8rem;
  }

  .pv-week-appointment .pv-appointment-time,
  .pv-week-appointment .pv-appointment-employee {
    font-size: 0.7rem;
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(239, 68, 68, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
}

.pv-appointment-libre {
  animation: pulse-red 2s infinite;
}
.pv-appointment-libre:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
}

.pv-appointment-in-progress {
  background-color: #FFEDD5;
  color: #9A3412;
  border-left-color: #FB923C;
}

.pv-appointment-in-progress .bg-white {
  background-color: rgba(255, 255, 255, 0.5);
}