.cm-container {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
  }
  
  .cm-header-toggle {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.5rem;
    background-color: #E5E7EB;
    border-radius: 9999px;
    transition: background-color 0.3s;
  }
  
  .cm-header-toggle:hover {
    background-color: #D1D5DB;
  }
  
  .cm-header {
    transition: all 0.3s ease-in-out;
    max-height: 1000px;
    opacity: 1;
    overflow: hidden;
  }
  
  .cm-header.hidden {
    max-height: 0;
    opacity: 0;
  }
  
  .cm-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .cm-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .cm-filter-button {
    margin-right: 1rem;
    color: #6B7280;
  }
  
  .cm-filter-button.active {
    color: #0F766E;
    font-weight: bold;
  }
  
  .cm-add-button {
    background-color: #3B82F6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
  }
  
  .cm-add-button:hover {
    background-color: #2563EB;
  }
  
  .cm-search-container {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .cm-search-input {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    border: 1px solid #D1D5DB;
    border-radius: 0.25rem;
  }
  
  .cm-search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9CA3AF;
  }
  
  .cm-client-list {
    list-style-type: none;
    padding: 0;
  }
  
  .cm-client-item {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .cm-client-info {
    display: flex;
    align-items: center;
  }
  
  .cm-client-color {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    margin-right: 1rem;
  }
  
  .cm-client-name {
    font-weight: 600;
  }
  
  .cm-client-details {
    font-size: 0.875rem;
    color: #6B7280;
  }
  
  .cm-client-actions {
    display: flex;
    align-items: center;
  }
  
  .cm-action-button {
    margin-left: 0.5rem;
    color: #3B82F6;
  }
  
  .cm-action-button:hover {
    color: #2563EB;
  }
  
  .cm-form {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .cm-form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0F766E;
    margin-bottom: 1.5rem;
  }
  
  .cm-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .cm-form-group {
    margin-bottom: 1rem;
  }
  
  .cm-form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4B5563;
    margin-bottom: 0.25rem;
  }
  
  .cm-form-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #D1D5DB;
    border-radius: 0.25rem;
  }
  
  .cm-form-input:focus {
    outline: none;
    border-color: #3B82F6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
  
  .cm-form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
  
  .cm-form-button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
  }
  
  .cm-form-button-cancel {
    margin-right: 0.5rem;
    border: 1px solid #D1D5DB;
    color: #4B5563;
  }
  
  .cm-form-button-save {
    background-color: #0F766E;
    color: white;
  }
  
  .cm-form-button-save:hover {
    background-color: #0E6B61;
  }
  
  .cm-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }