.pv-container.pv-month-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .pv-month-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pv-month-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .pv-day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
    background-color: #f3f4f6;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .pv-day-name {
    padding: 0.5rem;
    text-align: center;
    font-weight: bold;
    color: #4b5563;
  }
  
  .pv-month-grid-container {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .pv-month-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
    background-color: #e5e7eb;
  }
  
  .pv-day-cell {
    min-height: 100px;
    background-color: white;
    padding: 0.5rem;
    overflow-y: auto;
  }
/* Style pour la cellule en congé */
.pv-day-cell[data-on-leave="true"] {
  background-color: #fafafa;
  position: relative;
  cursor: not-allowed;
  border: 1px dashed #e5e7eb;
}
  
  .pv-date-number {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .pv-today {
    color: #3b82f6;
    background-color: #abc4e0;
  }
  
  .pv-current-month {
    background-color: white;
  }
  
  .pv-other-month {
    background-color: #f9fafb;
    color: #9ca3af;
  }
  
  .pv-empty-cell {
    background-color: #f3f4f6;
  }
  
  .pv-appointments-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .pv-appointment {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .pv-appointment:hover {
    transform: scale(1.02);
  }
  
  .pv-dragging {
    opacity: 0.5;
  }
  
  .pv-copy-icon-wrapper {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background-color: white;
    border-radius: 50%;
    padding: 0.25rem;
  }
  
  .pv-copy-icon {
    color: #3b82f6;
  }